import { build_message_obj } from "../Utils";

export const DEFAULT_INITIAL_SYSTEM_MESSAGE = build_message_obj(
    "system",
    "Hello! I am the Bill reviewer AI chatbot. My goal is to help you understand bills and its implications in simple language. Which bill do you want to look at?"
);
export const NO_BILL_IN_CONTEXT = "Only details of bill Senate Bill No. S297 and Senate Bill No. S2310 are available in trial/demo."
export const SIMILARITY_CONFIDENCE_PERCENTAGE = 50;
export const PREMIUM_FEATURE_MESSAGE = "Use trial key to get access to full features.";
export const US_BILL_JURISDICTIONS = [
    {
        "name": "New Jersey",
        "code": "NJ",
        "active": true,
    },
    {
        "name": "Congress",
        "code": "US",
        "active": false,
    },
    {
        "name": "Alabama",
        "code": "AL",
        "active": false,
    },
    {
        "name": "Alaska",
        "code": "AK",
        "active": false,
    },
    {
        "name": "Arizona",
        "code": "AZ",
        "active": false,
    },
    {
        "name": "Arkansas",
        "code": "AR",
        "active": false,
    },
    {
        "name": "California",
        "code": "CA",
        "active": false,
    },
    {
        "name": "Colorado",
        "code": "CO",
        "active": false,
    },
    {
        "name": "Connecticut",
        "code": "CT",
        "active": false,
    },
    {
        "name": "Delaware",
        "code": "DE",
        "active": false,
    },
    {
        "name": "Florida",
        "code": "FL",
        "active": false,
    },
    {
        "name": "Georgia",
        "code": "GA",
        "active": false,
    },
    {
        "name": "Hawaii",
        "code": "HI",
        "active": false,
    },
    {
        "name": "Idaho",
        "code": "ID",
        "active": false,
    },
    {
        "name": "Illinois",
        "code": "IL",
        "active": false,
    },
    {
        "name": "Indiana",
        "code": "IN",
        "active": false,
    },
    {
        "name": "Iowa",
        "code": "IA",
        "active": false,
    },
    {
        "name": "Kansas",
        "code": "KS",
        "active": false,
    },
    {
        "name": "Kentucky",
        "code": "KY",
        "active": false,
    },
    {
        "name": "Louisiana",
        "code": "LA",
        "active": false,
    },
    {
        "name": "Maine",
        "code": "ME",
        "active": false,
    },
    {
        "name": "Maryland",
        "code": "MD",
        "active": false,
    },
    {
        "name": "Massachusetts",
        "code": "MA",
        "active": false,
    },
    {
        "name": "Michigan",
        "code": "MI",
        "active": false,
    },
    {
        "name": "Minnesota",
        "code": "MN",
        "active": false,
    },
    {
        "name": "Mississippi",
        "code": "MS",
        "active": false,
    },
    {
        "name": "Missouri",
        "code": "MO",
        "active": false,
    },
    {
        "name": "Montana",
        "code": "MT",
        "active": false,
    },
    {
        "name": "Nebraska",
        "code": "NE",
        "active": false,
    },
    {
        "name": "Nevada",
        "code": "NV",
        "active": false,
    },
    {
        "name": "New Hampshire",
        "code": "NH",
        "active": false,
    },
    {
        "name": "New Mexico",
        "code": "NM",
        "active": false,
    },
    {
        "name": "New York",
        "code": "NY",
        "active": false,
    },
    {
        "name": "North Carolina",
        "code": "NC",
        "active": false,
    },
    {
        "name": "North Dakota",
        "code": "ND",
        "active": false,
    },
    {
        "name": "Ohio",
        "code": "OH",
        "active": false,
    },
    {
        "name": "Oklahoma",
        "code": "OK",
        "active": false,
    },
    {
        "name": "Oregon",
        "code": "OR",
        "active": false,
    },
    {
        "name": "Pennsylvania",
        "code": "PA",
        "active": false,
    },
    {
        "name": "Rhode Island",
        "code": "RI",
        "active": false,
    },
    {
        "name": "South Carolina",
        "code": "SC",
        "active": false,
    },
    {
        "name": "South Dakota",
        "code": "SD",
        "active": false,
    },
    {
        "name": "Tennessee",
        "code": "TN",
        "active": false,
    },
    {
        "name": "Texas",
        "code": "TX",
        "active": false,
    },
    {
        "name": "Utah",
        "code": "UT",
        "active": false,
    },
    {
        "name": "Vermont",
        "code": "VT",
        "active": false,
    },
    {
        "name": "Virginia",
        "code": "VA",
        "active": false,
    },
    {
        "name": "Washington",
        "code": "WA",
        "active": false,
    },
    {
        "name": "West Virginia",
        "code": "WV",
        "active": false,
    },
    {
        "name": "Wisconsin",
        "code": "WI",
        "active": false,
    },
    {
        "name": "Wyoming",
        "code": "WY",
        "active": false,
    }
]
