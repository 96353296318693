import client from "./axiosConfig";

export const openAICompletionService = (messages) => {
    const config = {
        url: "https://knowyourbill-backend.vercel.app/api/openai",
        method: "POST",
        data: messages
    }
    const response = client(config);
    return response;
}
