import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import * as Constants from "../Constants";
import * as dummy_chat from "../Constants/dummy_chat";
import * as openaiService from "../Services/openaiService";

import * as Utils from "../Utils";
import { PROMPTS_TITLES } from "../Constants/prompts";

export const AppContext = React.createContext();

const AppContextProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [billNo, setBillNo] = useState(null);
    const [billJurisdiction, setBillJurisdiction] = useState(null);
    const [billText, setBillText] = useState(null);
    const [messages, setMessages] = useState([]);

    // Get query params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isLiveMode = queryParams.get('live');

    const generateMockResponses = (userMessageText, promptTitle, isPremiumMock = false) => {
        /**
         * Generate mock responses for the user message
         * 
         * @param {*} userMessageText User message text
         * @param {*} promptTitle Prompt title
         * */

        setIsLoading(true);
        let userDummyChats = [];
        if (promptTitle) {
            userDummyChats = dummy_chat.dummy_chat_messages.filter(
                (message) => message.id === promptTitle && message.role === "user"
            );
            // Update user text to dummy text instead of user message
            userMessageText = userDummyChats[0].content;
        } else {
            userDummyChats = [Utils.build_message_obj("user", userMessageText)];
        }
        setMessages((prevMessages) => [...prevMessages, ...userDummyChats]);

        // Get machine response
        let machineDummyChats = Utils.getMachineResponseForUserInput(userMessageText, billNo, isPremiumMock);
        setTimeout(() => {
            setMessages((prevMessages) => [...prevMessages, ...machineDummyChats]);
            setIsLoading(false);
        }, 2000);
    };

    const postMessage = (messageText, promptTitle = null) => {
        /**
         * Post the message to the langflow API and get the response messages 
         * 
         * @param {*} messageText Message text to be posted 
         */

        if (!messageText) return;
        // Mock messages even in premium

        const isPremiumMode = isLiveMode === 'nj'
        const isPremiumMock = isPremiumMode && [PROMPTS_TITLES.LIST_BILLS, PROMPTS_TITLES.BILL_DETAILS].indexOf(promptTitle) != -1

        if (isPremiumMode && !isPremiumMock) {
            setIsLoading(true);
            const userMessage = Utils.build_message_obj("user", messageText)
            const latest_messages = [...messages, userMessage]
            setMessages(latest_messages);

            if (billNo) {
                const _billNo = billNo.replace(/\D/g, '')
                if (!['297', '2310'].includes(_billNo)) {
                    const responseMessageObj = Utils.build_message_obj("system", Constants.NO_BILL_IN_CONTEXT)
                    setTimeout(() => {
                        setMessages([...messages, ...[userMessage, responseMessageObj]]);
                        setIsLoading(false);
                    }, 1500);
                    return
                }
            }

            const last_5_messages = latest_messages.slice(Math.max(latest_messages.length - 5, 0))
            openaiService.openAICompletionService(last_5_messages).then(response => {
                if (response.status === 200) {
                    const responseMessage = response.data.choices[0].message.content;
                    const responseMessageObj = Utils.build_message_obj("system", responseMessage)
                    setMessages([...messages, ...[userMessage, responseMessageObj]]);
                }
                else {
                    toast.error("Something went wrong! Please contact administrator.", { duration: 3000 });
                }
            }).catch(error => {
                console.log(error);
                toast.error("Something went wrong! Please contact administrator.", { duration: 3000 });
            }).finally(() => { setIsLoading(false); });
        } else {
            generateMockResponses(messageText, promptTitle, isPremiumMock);
        }
    }

    const values = {
        billNo,
        setBillNo,
        billJurisdiction,
        setBillJurisdiction,
        billText,
        setBillText,
        isLoading,
        setIsLoading,
        messages,
        setMessages,
        postMessage,
    };

    useEffect(() => {
        if (isLiveMode != null && isLiveMode != 'nj') {
            window.alert("Live mode value is not correct!")
            window.location = "/"
        }
        setMessages([Constants.DEFAULT_INITIAL_SYSTEM_MESSAGE]);
    }, [isLiveMode])

    return (
        <AppContext.Provider value={values}>
            {children}
        </AppContext.Provider>
    );
};
export default AppContextProvider;